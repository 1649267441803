/* src/components/SurgicalItemsSupply.css */

.surgical-items-supply {
  padding: 0 0;
}

.service-description {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.service-description:hover {
  transform: translateY(-10px);
}

.service-highlights {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.service-highlights:hover {
  transform: translateY(-10px);
}

.service-highlights .MuiListItemIcon-root {
  min-width: 40px;
}

.service-highlights .MuiListItemText-primary {
  font-weight: bold;
  font-size: 1.1rem;
}

.service-highlights .MuiListItemText-secondary {
  color: #666666;
  font-size: 0.9rem;
}
