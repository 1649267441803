.authModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 24px;
    padding: 20px;
    outline: 0;
  }
  
  .authModalContent {
    padding: 20px;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .upload-section input {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .image-preview {
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin-top: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 5px;
  }
  