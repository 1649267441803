/* src/components/MissionVision.css */

.mission-vision {
  padding: 40px 0;
}

.mission-vision .mission-vision-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.mission-vision .mission-vision-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.mission-vision .mission-vision-icon {
  font-size: 40px;
  color: #3f51b5;
}

.mission-vision h5 {
  font-weight: 700;
  margin-bottom: 10px;
}

.mission-vision p {
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .mission-vision {
    padding: 20px 0;
  }

  .mission-vision .mission-vision-item {
    margin-bottom: 15px;
  }

  .mission-vision .mission-vision-icon {
    font-size: 35px;
  }
}
