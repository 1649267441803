/* src/components/ProductModal.css */



.product-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-box {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  background: linear-gradient(135deg, #ece9e6, #ffffff);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-title {
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  overflow: hidden;
  margin-bottom: 20px;
}

.responsive-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.modal-details {
  margin-top: 20px;
  text-align: left;
  width: 100%;
}

.modal-details p {
  margin: 10px 0;
}

.more-info-link {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-decoration: none;
}

.more-info-link Button {
  padding: 10px 20px;
  border-radius: 50px;
  background: linear-gradient(135deg, #667eea, #764ba2);
  color: white;
}

.more-info-link Button:hover {
  background: linear-gradient(135deg, #764ba2, #667eea);
}
