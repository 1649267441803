/* src/components/CompanyHistory.css */

.company-history {
    padding: 60px 0;
  }
  
  .company-history .history-section {
    margin-top: 20px;
  }
  
  .company-history .history-card {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .company-history .history-content {
    padding-top: 20px;
  }
  
  .company-history .section-title {
    color: #3f51b5;
    margin-top: 20px;
    font-weight: bold;
  }
  