/* .modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
  }
  
  .closeButton {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  
  .upload-section {
    padding: 24px;
    text-align: center;
    border-radius: 8px;
    background-color: #f3f4f6;
  }
  
  .upload-section input[type="file"] {
    display: none;
  }
  
  
  */


  .upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
  }
  
  #raised-button-file {
    display: none;
  }
  
  .upload-section label {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
  }
  
  .upload-section button {
    margin-top: 15px;
    width: 100%;
  }
  