.header {
  width: 100%;
  background-color: #f8f8f8;
  font-family: Arial, sans-serif;
}

.top-bar {
  padding: 10px 0;
  font-size: 14px;
}

.top-bar-left p {
  margin: 0;
}

.top-bar-left a {
  color: #333;
  text-decoration: none;
}

.top-bar-right a {
  margin-left: 15px;
  color: #333;
  text-decoration: none;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background-color: #11a193;
}

.logo img {
  height: 88px;
}

.search-bar {
  display: flex;
  flex-grow: 1;
  margin: 0 20px;
  position: relative;
  background-color: white;
  border-radius: 5px;
}

.search-input {
  flex-grow: 1;
  padding: 10px 20px;
  border: none;
  border-radius: 5px 0 0 5px;
}

.search-button {
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}

.search-button .MuiSvgIcon-root {
  color: #666;
}

.header-links {
  display: flex;
  align-items: center;
}

.header-links a {
  margin-left: 20px;
  color: white;
  text-decoration: none;
  position: relative;
}

.cart-count {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .top-bar, .main-header {
    flex-direction: column;
    align-items: center;
  }

  .search-bar {
    width: 100%;
    margin: 10px 0;
  }

  .top-bar-right a, .header-links a {
    margin: 5px 0;
  }
}


/* Customize the menu item styles here */
.menu-item {
  padding: 8px 16px;
}

.menu-link {
  text-decoration: none;
  color: inherit;
}

.menu-link:hover {
  color: #6200EE; /* Change color on hover */
}



.menu-section {
  display: flex;
  align-items: center;
}

.menu-icon-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.menu-button {
  margin-left: auto;
}

.drawer-link {
  text-decoration: none;
  color: inherit;
  width: 100%;
}

.categories-menu {
  z-index: 1300; /* Ensure it's above other elements */
}

.drawer-header {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}


@media (max-width: 600px) {
  .top-bar-right {
    display: none; /* Hide top-bar links on mobile */
  }

  .search-bar-container {
    width: 100%;
  }

  .menu-button {
    display: block; /* Show menu button on mobile */
  }
}